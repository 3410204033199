/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.26 at 18:48
 */

import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isArray} from '@labor-digital/helferlein/lib/Types/isArray';
import {isPlainObject} from '@labor-digital/helferlein/lib/Types/isPlainObject';
import TextMixin from './TextMixin';

export default {
    mixins: [TextMixin],
    methods: {
        getMetaTags(): Array<PlainObject>
        {
            return [
                {'property': 'og:image', 'content': this.ogImage, 'vmid': 'og:image'},
                {'property': 'og:title', 'content': this.ogTitle, 'vmid': 'og:title'},
                {'property': 'og:description', 'content': this.ogDescription, 'vmid': 'og:description'}
            ];
        },
        getOgTitle(text): string
        {
            return this.truncate(text, 95);
        },
        getOgDescription(text): string
        {
            return this.truncate(this.stripTags(text), 297);
        },
        getOgImage(images): string
        {
            if (!isArray(images) || !isPlainObject(images[0])) {
                return '';
            }
            return images[0].url;
        }
    },
    computed: {
        metaTags(): Array<PlainObject>
        {
            return [
                this.ogImage ? {'property': 'og:image', 'content': this.ogImage, 'vmid': 'og:image'} : {},
                this.ogTitle ? {'property': 'og:title', 'content': this.ogTitle, 'vmid': 'og:title'} : {},
                this.ogDescription ? {'property': 'og:description', 'content': this.ogDescription, 'vmid': 'og:description'} : {}
            ];
        }
    },
    metaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags
        };
    }
};
