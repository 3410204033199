


















import {getAttr} from '@labor-digital/helferlein/lib/Dom/getAttr';
import {getData} from '@labor-digital/helferlein/lib/Dom/getData';
import {escapeRegex} from '@labor-digital/helferlein/lib/Strings/escapeRegex';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isString} from '@labor-digital/helferlein/lib/Types/isString';
import {isUndefined} from '@labor-digital/helferlein/lib/Types/isUndefined';
import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import Url from 'url-parse';
import {CreateElement, VNode} from 'vue';

export default {
    name: 'CmsTextLinkBlock',
    render(createElement: CreateElement): VNode | VNode[]
    {
        const vNode: VNode = this.$scopedSlots.default({})[0];
        if (isUndefined(vNode.data.on)) {
            vNode.data.on = {};
        }
        vNode.data.on.click = (e) => {
            this.onClick(e);
        };
        return createElement('div', {
            class: {
                'cmsText': true
            }
        }, [vNode]);
    },
    methods: {
        onClick(e: MouseEvent)
        {
            if (!isString((
                e.target as HTMLElement
            ).tagName)) {
                return;
            }

            if (getData((e.target as HTMLElement), 'ignore-cms-link', false) !== false) {
                return;
            }

            const target: HTMLElement = e.target as any;
            if (target.tagName !== 'A') {
                return;
            }
            const href = target.getAttribute('href');

            // Block the default handling
            e.stopPropagation();
            e.preventDefault();

            // Check if we got a mail link
            if (href.indexOf('#mailer') !== -1) {
                let mailTarget = getData(target, 'm-target');
                if (isString(mailTarget) && !isEmpty(mailTarget)) {
                    window.location.href = 'mailto:' + mailTarget.replace(/\//g, '@');
                }
                return false;
            }

            // Check if the given href points to an external host
            const appContext: AppContext = this.$root.appContext;
            const url = new Url(href);
            const baseUrl = new Url(appContext.pageContext.baseUrl);
            const isExternal = url.host !== baseUrl.host || getAttr(target, 'target') === '_blank';

            // Handle internal url
            if (!isExternal) {
                appContext.pageContext.router.push(href.replace(new RegExp('^' + escapeRegex(url.origin)), ''));
            }// Handle external url
            else {
                window.open(href, '_blank', 'noopener');
            }

            // Done
            return false;
        }
    }
};
