/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.26 at 18:48
 */

import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';

export default {
    methods: {
        stripTags(text): string
        {
            return text.replace(/(<([^>]+)>)/ig, '');
        },
        truncate(text, length): string
        {
            if (isEmpty(text)) {
                return '';
            }
            return text.substr(0, length);
        }
    }
};
