




























import {isBrowser} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {State} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import CmsTextLinkBlock from '../../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import ShareButton from '../../../../Component/Misc/ShareButton/ShareButton.vue';
import OpenGraphMixin from '../../../../Mixin/OpenGraphMixin';
import {JsonLdService} from '../../../../Service/JsonLdService';

export default {
    name: 'CareDisplayDetail',
    mixins: [OpenGraphMixin],
    components: {ShareButton, CmsTextLinkBlock},
    props: {
        context: null as ContentElementContext
    },
    computed: {
        article(): State
        {
            return this.context.initialState;
        },
        ogTitle(): string
        {
            return this.getOgTitle(this.article.get('title'));
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.article.get('seoText') || this.article.get('teaser'));
        },
        ogImage(): string
        {
            return this.getOgImage(this.article.get('teaserImages'));
        },
        ogUrl(): string
        {
            if (!isBrowser()) {
                return '';
            }
            return encodeURIComponent(window.location.href + '');
        }
    },
    created()
    {
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.article.get('headline') || this.article.get('title'));
        this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE, this.article.get('teaserImages'));
        this.context.pageContext.pageMeta.setCanonical(this.article.get('link'));
    },
    metaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags.concat([
                {'name': 'description', 'content': this.ogDescription, 'vmid': 't3-description'}
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.ogTitle),
                JsonLdService.makeBlogPost(this.ogTitle, this.ogDescription, this.ogImage)
            ]
        };
    }
};
